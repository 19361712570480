import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const NavbarComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [companies, setCompanies] = useState([]);

  const getCompany = async () => {
    const response = await api.get("/company");
    return response.data;
  };

  useEffect(() => {
    const getAllCompanyDetails = async () => {
      const allCompanyData = await getCompany();
      if (allCompanyData) {
        setIsLoaded(true);
        setCompanies(allCompanyData);
      }
    };

    getAllCompanyDetails();
  }, []);
  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container fluid>
        {companies.map((item) => (
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                className="footerLogo"
                src={"/api/images/" + item.image}
                alt={"/api/images/" + item.image}
              />
            </Navbar.Brand>
          </LinkContainer>
        ))}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/madagascar">
              <Nav.Link>Our Destinations</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/specials">
              <Nav.Link>Our Specials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact-us">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
