import React from "react";
import { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import api from "../../api/mars_api";

import { Form, Button, Row, Col, Alert, FloatingLabel } from "react-bootstrap";

const Special = () => {
  const [number_of_days, setNumber_of_days] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [number_of_adults, setNumber_of_adults] = useState(0);
  const [number_of_kids, setNumber_of_kids] = useState("");
  const [full_name, setFull_name] = useState("");
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const form = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);
  const lodge_name = hotel.lodge_name;
  const special_name = hotel.special_name;

  const createHandler = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("number_of_kids", number_of_kids);
    formData.append("number_of_adults", number_of_adults);
    formData.append("number_of_days", number_of_days);
    formData.append("email", email);
    formData.append("special_name", special_name);
    formData.append("lodge_name", lodge_name);
    formData.append("phoneNumber", phoneNumber);

    await fetch(`/api/bookspecial`, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        // sendEmail();
        console.log(number_of_adults);
        setShow(true);
      })
      .catch(e => {});
  };

  //   const sendEmail = e => {
  //     emailjs
  //       .sendForm(
  //         "service_aaqeqrs",
  //         "template_ib25fzh",
  //         form.current,
  //         "Bth1v4UDWPT4TLf7n"
  //       )
  //       .then(
  //         result => {
  //           console.log(result.text);
  //         },
  //         error => {
  //           console.log(error.text);
  //         }
  //       );
  //   };

  const getHotel = async () => {
    const response = await api.get(`special/${id}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else {
    return (
      <div>
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              <b>RUNNING SPECIAL</b>
            </h5>

            <h5 className="booking-h5">{hotel.special_name}</h5>

            <p className="booking-p">
              Marie Lou Simaan the director of the company has been in the
              travel business for 37 years and has gained sustainable knowledge
              of the industry. All the staff presently employed are of an
              immediate and senior status and well travelled. We felt it was
              necessary to establish ourselves as a professional, valued and
              competitive agency in the world of travel which we felt was
              lacking.
            </p>
          </div>
        </div>

        <Alert className="mb-5" show={show} variant="success">
          <Alert.Heading>Hi there!</Alert.Heading>
          <p>Thank you, your enquiry has been submitted successfully</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-success">
              Close me
            </Button>
          </div>
        </Alert>
        <div className="main-book">
          <div className="row">
            <div className="col">
              <div className="card-container-booking-form">
                <form className="form" ref={form}>
                  <div className="">
                    <h4 className="prefer">SELECT YOUR PREFERENCES</h4>
                    <p className="text-center">{hotel.comment}</p>
                  </div>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Label>Select Number Of Days</Form.Label>
                        <Form.Select
                          type="checkbox"
                          name="roomList"
                          label="Check me out"
                          onChange={e => setNumber_of_days(e.target.value)}
                        >
                          <option disabled selected>
                            Select Number Of Days
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Label>Select Number Of Adults</Form.Label>
                        <Form.Select
                          type="checkbox"
                          name="roomList"
                          label="Check me out"
                          onChange={e => setNumber_of_adults(e.target.value)}
                        >
                          <option disabled selected>
                            Select Number Of Adults
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>8</option>
                          <option>9</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Label>Select Number Of Kids</Form.Label>
                        <Form.Select
                          type="checkbox"
                          name="roomList"
                          label="Check me out"
                          onChange={e => setNumber_of_kids(e.target.value)}
                        >
                          <option disabled selected>
                            Select Number Of Kids
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>8</option>
                          <option>9</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Full Name(s)</Form.Label>
                      <Form.Group className=" mb-3">
                        <Form.Control
                          type="name"
                          name="text"
                          placeholder="Full Name(s)"
                          onChange={e => setFull_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Cell Number</Form.Label>
                      <Form.Group className=" mb-3">
                        <Form.Control
                          type="number"
                          name="phoneNumber"
                          placeholder="Cell Number "
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className=" mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Email address "
                          onChange={e => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-grid gap-2">
                    <Button
                      disabled={
                        (!number_of_days,
                        !email,
                        !phoneNumber,
                        !full_name,
                        !number_of_adults,
                        !number_of_kids)
                      }
                      onClick={createHandler}
                      variant="dark"
                      type="submit"
                    >
                      Enquire Now
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-m-2">
              <div className="card-container-booking text-center">
                <div className="row ">
                  <div className="col col-lg-6">
                    <div className="mt-3">
                      <strong>{hotel.special_name}</strong>
                    </div>
                    <h5 className="mt-3 mb-3">{hotel.lodge_name}</h5>
                    <div>
                      <span>
                        {" "}
                        <i class="fa-solid fa-location-pin address-icon"></i>
                      </span>
                      {hotel.address}
                    </div>
                  </div>

                  <div className="col  mt-4">
                    <div className="mb-3">
                      <strong>THIS INCLUDES</strong>
                    </div>
                    <ul>
                      <li> Break-Fast: R {hotel.breakfast_price}</li>
                      <li> Lunch: R {hotel.lunch_price}</li>
                      <li> Dinner: R {hotel.dinner_price}</li>
                      <li> {hotel.game_drives} Game Drives</li>
                    </ul>
                    <hr />
                    <p>
                      <strong> NB.</strong> This special is running from{" "}
                      <strong>{hotel.start_date}</strong> To{" "}
                      <strong>{hotel.end_date}</strong>. Enquire before the
                      special runs out.
                    </p>
                  </div>
                </div>
                <div>
                  {" "}
                  <hr />
                </div>
                <div className="about-booking"></div>
                <p className="text-center">{hotel.comment}</p>
                <div>
                  <hr />
                  <ul>
                    <li>
                      {" "}
                      <strong>Adult:</strong>: R {hotel.adult_price}
                    </li>
                    <li>
                      {" "}
                      <strong>Children:</strong> R {hotel.kids_price}
                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="row">
                  <div className="col col-lg-6 ">
                    <img
                      className=" image-booking-main"
                      src={"/api/images/" + hotel.image1}
                      alt=""
                    />
                  </div>

                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <img
                          className=" image-booking"
                          src={"/api/images/" + hotel.image2}
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <img
                          className=" image-booking"
                          src={"/api/images/" + hotel.image3}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img
                          className=" image-booking"
                          src={"/api/images/" + hotel.image4}
                          alt=""
                        />
                      </div>
                      <div className="col">
                        {" "}
                        <img
                          className=" image-booking"
                          src={"/api/images/" + hotel.image5}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
      </div>
    );
  }
};

export default Special;
