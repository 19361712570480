import React from "react";
import BookingContainer from "./BookingContainer";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../../api/mars_api";

const MauritiusComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);

  const getHotel = async () => {
    const response = await api.get(`locationById/${18}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (hotel.length > 0) {
    return (
      <>
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>MAURITIUS</b>
            </h5>

            <p className="booking-p">
              This is a place where the warm turquoise and aquamarine waters of
              the Indian Ocean present a vivid contrast as they gently lap up on
              the soft white sandy beaches set against a backdrop of totally
              unspoilt natural beauty. The beaches of the Seychelles have been
              described as being the most beautiful in the world and few people
              would disagree. It is hardly surprising therefore that these
              islands are also sometimes referred to as the Paradise Islands.
              For many travellers, the beaches alone would put the Seychelles
              high on any “Places to Visit” list but these islands have much
              more to offer.
            </p>
          </div>
        </div>
        {hotel.map((item) => (
          <BookingContainer
            name={item.name}
            location={item.location}
            address={item.address}
            stars={item.stars}
            comment={item.comment}
            price={item.price}
            image1={item.image1}
            image2={item.image2}
            image3={item.image3}
            image4={item.image4}
            image5={item.image5}
            id={item.id}
            parking={item.parking}
            spa={item.spa}
            minbar={item.minbar}
            bathtub={item.bathtub}
            wifi={item.wifi}
            ocean={item.ocean}
            beach={item.beach}
            secure={item.secure}
            aircon={item.aircon}
            transportion={item.transportion}
            about={item.about}
          />
        ))}
      </>
    );
  }
};

export default MauritiusComponent;
