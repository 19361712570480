import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const HotelCard = ({ title, description, id }) => {
  return (
    <>
      <Card className="hotelCard text-center">
        <Card.Header className="text-center">{title}</Card.Header>
        <div className="body-card">
          <p> {description} </p>

          {id === 19 ? (
            <Link to="/africa">
              <Button className="mt-5" variant="info">
                VIEW OFFER
              </Button>
            </Link>
          ) : (
            <></>
          )}
          {id === 18 ? (
            <Link to="/mauritius">
              <Button className="mt-5" variant="light">
                VIEW OFFER
              </Button>
            </Link>
          ) : (
            <></>
          )}

          {id === 17 ? (
            <Link to="/madagascar">
              <Button className="mt-5" variant="info">
                VIEW OFFER
              </Button>
            </Link>
          ) : (
            <></>
          )}

          {id === 16 ? (
            <Link to="/secyhelles">
              <Button className="mt-5" variant="light">
                VIEW OFFER
              </Button>
            </Link>
          ) : (
            <></>
          )}

          {id === 15 ? (
            <Link to="/maildves">
              <Button className="mt-5" variant="info">
                VIEW OFFER
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </Card>
    </>
  );
};

export default HotelCard;
