import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SpecialCard = ({ start_date, end_date, special_name, id }) => {
  return (
    <div className="row bg-light s-card">
      <div className="col m-2">
        <h5 className="s-card ">
          <Link to={`special/${id}`}>{special_name}</Link>
        </h5>
        <p>
          Valid Between:{" "}
          <strong>
            {start_date} to {end_date}
          </strong>
        </p>
      </div>

      <div className="col m-4">
        <Link to={`special/${id}`}>
          <Button>VIEW SPECIAL</Button>
        </Link>
      </div>
    </div>
  );
};

export default SpecialCard;
