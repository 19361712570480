import React from "react";
import { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

import { Form, Button, Row, Col, Alert, FloatingLabel } from "react-bootstrap";

const BookingContainer = ({
  location_id,
  name,
  location,
  address,
  about,
  price,
  image1,
  image2,
  image3,
  image4,
  image5,
  comment,
  parking,
  spa,
  minbar,
  bathtub,
  wifi,
  ocean,
  beach,
  secure,
  aircon,
  transportion,
}) => {
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [roomList, setRoomList] = useState(0);
  const [adultNumber, setAdultNumber] = useState("");
  const [childrenNumber, setClidrenNumber] = useState("");
  const [tellUs, setTellUs] = useState("");
  const [checkIn, setCheckIn] = useState(0);
  const [checkOut, setCheckOut] = useState(0);
  const [show, setShow] = useState(false);
  const form = useRef();

  const createHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("location", location);
    formData.append("location_id", location_id);
    formData.append("price", price);

    formData.append("clientName", clientName);
    formData.append("tellUs", tellUs);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("adultNumber", adultNumber);
    formData.append("childrenNumber", childrenNumber);
    formData.append("checkIn", checkIn);
    formData.append("checkOut", checkOut);
    formData.append("roomList", roomList);

    await fetch(`/api/enquirebooking`, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        sendEmail();
        setShow(true);

        console.log(name);
      })
      .catch((e) => {});
  };

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_aaqeqrs",
        "template_ib25fzh",
        form.current,
        "Bth1v4UDWPT4TLf7n"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      {location_id === 19 ? (
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>AFRICA</b>
            </h5>

            <p className="booking-p">
              Africa is the world's second-largest and second-most populous
              continent, after Asia in both cases. At about 30.3 million km2
              (11.7 million square miles) including adjacent islands, it covers
              6% of Earth's total surface area and 20% of its land area.[7] With
              1.3 billion people[1][2] as of 2018, it accounts for about 16% of
              the world's human population.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {location_id === 17 ? (
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>MADAGASCAR</b>
            </h5>

            <p className="booking-p">
              This is a place where the warm turquoise and aquamarine waters of
              the Indian Ocean present a vivid contrast as they gently lap up on
              the soft white sandy beaches set against a backdrop of totally
              unspoilt natural beauty. The beaches of the Seychelles have been
              described as being the most beautiful in the world and few people
              would disagree. It is hardly surprising therefore that these
              islands are also sometimes referred to as the Paradise Islands.
              For many travellers, the beaches alone would put the Seychelles
              high on any “Places to Visit” list but these islands have much
              more to offer.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {location_id === 15 ? (
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>MALDIVES</b>
            </h5>

            <p className="booking-p">
              The Maldives are the stuff dreams are made of -- as in dream
              vacations inspired by those impossibly perfect pictures you've
              seen, or those pesky fantasies about quitting your day job to live
              on an exotic island for the rest of your life. Bright sun,
              transparent blue lagoons, house reefs teeming with fish, low
              waters with baby reef sharks and rays, and white sand beaches that
              stretch out into nothing but miles and miles of turquoise waters
              and blue skies.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {location_id === 16 ? (
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>SECYHELLES</b>
            </h5>

            <p className="booking-p">
              This is a place where the warm turquoise and aquamarine waters of
              the Indian Ocean present a vivid contrast as they gently lap up on
              the soft white sandy beaches set against a backdrop of totally
              unspoilt natural beauty. The beaches of the Seychelles have been
              described as being the most beautiful in the world and few people
              would disagree. It is hardly surprising therefore that these
              islands are also sometimes referred to as the Paradise Islands.
              For many travellers, the beaches alone would put the Seychelles
              high on any “Places to Visit” list but these islands have much
              more to offer.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {location_id === 18 ? (
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>MAURITIUS</b>
            </h5>

            <p className="booking-p">
              This is a place where the warm turquoise and aquamarine waters of
              the Indian Ocean present a vivid contrast as they gently lap up on
              the soft white sandy beaches set against a backdrop of totally
              unspoilt natural beauty. The beaches of the Seychelles have been
              described as being the most beautiful in the world and few people
              would disagree. It is hardly surprising therefore that these
              islands are also sometimes referred to as the Paradise Islands.
              For many travellers, the beaches alone would put the Seychelles
              high on any “Places to Visit” list but these islands have much
              more to offer.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Alert className="mb-5" show={show} variant="success">
        <Alert.Heading>Hi there!</Alert.Heading>
        <p>Thank you, your enquiry has been submitted successfully</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close me
          </Button>
        </div>
      </Alert>
      <div className="main-book">
        <div className="row">
          <div className="col">
            <div className="card-container-booking-form">
              <form className="form" ref={form}>
                <Row>
                  <Form.Group className=" mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Please Enter Your Full Name(s) "
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Please Enter Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      placeholder="Your mobile number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form.Group className=" mb-3">
                      <Form.Control
                        type="number"
                        name="adultNumber"
                        placeholder="Number Of Adults "
                        onChange={(e) => setAdultNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className=" mb-3">
                      <Form.Control
                        type="text"
                        name="childNumber"
                        placeholder="Number Of Kids "
                        onChange={(e) => setClidrenNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Check In Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="checkIn"
                        placeholder="Check In "
                        onChange={(e) => setCheckIn(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Check Out Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="checkOut"
                        placeholder="Check Out "
                        onChange={(e) => setCheckOut(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Label>Select Room Preference</Form.Label>
                      <Form.Select
                        type="checkbox"
                        name="roomList"
                        label="Check me out"
                        onChange={(e) => setRoomList(e.target.value)}
                      >
                        <option disabled selected>
                          Choose a room from the list
                        </option>
                        <option>Connecting</option>
                        <option>Adjoining</option>
                        <option>Adjacent</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Label>
                  Tell us anything else that might be important
                </Form.Label>
                <FloatingLabel className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Tell us anything else that might be important"
                    onChange={(e) => setTellUs(e.target.value)}
                    style={{ height: "100px" }}
                    name="tellUs"
                    type="text"
                  />
                </FloatingLabel>
                <div className="d-grid gap-2">
                  <Button
                    disabled={
                      !clientName ||
                      !email ||
                      !phoneNumber ||
                      !roomList ||
                      !childrenNumber ||
                      !adultNumber ||
                      !tellUs ||
                      !checkIn ||
                      !checkOut
                    }
                    onClick={createHandler}
                    variant="dark"
                    type="submit"
                  >
                    Enquire Now!
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-8 col-m-2">
            <div className="card-container-booking text-center">
              <div className="row ">
                <div className="col col-lg-6">
                  <h5 className="mt-2 ">{name}</h5>
                  {location}
                  <div>
                    <span>
                      {" "}
                      <i class="fa-solid fa-location-pin address-icon"></i>
                    </span>
                    {address}
                  </div>
                </div>

                <div className="col price mt-4">
                  <div className="">FROM ZAR {price}</div>
                  <div className="">{comment}</div>
                </div>
              </div>
              <div>
                {" "}
                <hr />
              </div>
              <div className="about-booking"></div>
              <p className="text-center">{about}</p>
              <div>
                <hr />
                <div className="icons">
                  <Row>
                    <Col>
                      <div>
                        <b>Parking:</b>
                        {parking === 0 ? (
                          <i className="fa-solid fa-square-parking icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Spa:</b>
                        {spa === 0 ? (
                          <i className="fa-solid fa-spa icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Minbar:</b>
                        {minbar === 0 ? (
                          <i className="fa-solid fa-wine-glass icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Bathtub:</b>
                        {bathtub === 0 ? (
                          <i className="fa-solid fa-bath icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Wifi:</b>

                        {wifi === 0 ? (
                          <i className="fa-solid fa-wifi icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <b>Ocean:</b>

                        {ocean === 0 ? (
                          <i className="fa-solid fa-person-swimming icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Beach:</b>

                        {beach === 0 ? (
                          <i className="fa-solid fa-umbrella-beach icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Secure:</b>

                        {secure === 0 ? (
                          <i className="fa-solid fa-key icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Aircon:</b>

                        {aircon === 0 ? (
                          <i className="fa-solid fa-fan icons "></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <b>Transportion:</b>

                        {transportion === 0 ? (
                          <i className="fa-solid fa-bus icons"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark icon-empty"></i>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr />
              </div>
              <div className="row">
                <div className="col col-lg-6 ">
                  <img
                    className=" image-booking-main"
                    src={"/api/images/" + image1}
                    alt=""
                  />
                </div>

                <div className="col">
                  <div className="row">
                    <div className="col">
                      <img
                        className=" image-booking"
                        src={"/api/images/" + image2}
                        alt=""
                      />
                    </div>
                    <div className="col">
                      <img
                        className=" image-booking"
                        src={"/api/images/" + image3}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        className=" image-booking"
                        src={"/api/images/" + image4}
                        alt=""
                      />
                    </div>
                    <div className="col">
                      {" "}
                      <img
                        className=" image-booking"
                        src={"/api/images/" + image5}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default BookingContainer;
