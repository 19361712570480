import React from "react";
import HeaderImage from "../Images/header.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import HeaderEnquireForm from "./HeaderEnquireForm";

const Header = () => {
  return (
    <header>
      <div className="imageHeaderContainer">
        <img className="headerImage" src={HeaderImage} alt="test" />
      </div>
      <div className="headerText container text-center">
        <h2 className="">
          YOUR <b>JOURNEY</b> AWAITS
        </h2>

        <FontAwesomeIcon size="2x" icon={faEllipsisVertical} />
        <h3>We assist you to choose the best</h3>
        <FontAwesomeIcon
          className="mt-3"
          size="2x"
          icon={faArrowAltCircleDown}
        />
        <div className="mt-5 ">
          <HeaderEnquireForm />
        </div>
      </div>
    </header>
  );
};

export default Header;
