import React from "react";
import BookingContainer from "./BookingContainer";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../../api/mars_api";
import BookingComponent from "./BookingComponent";

const MaildvesComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);

  const getHotel = async () => {
    const response = await api.get(`locationById/${15}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (hotel.length > 0) {
    return (
      <>
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>MALDIVES</b>
            </h5>

            <p className="booking-p">
              The Maldives are the stuff dreams are made of -- as in dream
              vacations inspired by those impossibly perfect pictures you've
              seen, or those pesky fantasies about quitting your day job to live
              on an exotic island for the rest of your life. Bright sun,
              transparent blue lagoons, house reefs teeming with fish, low
              waters with baby reef sharks and rays, and white sand beaches that
              stretch out into nothing but miles and miles of turquoise waters
              and blue skies.
            </p>
          </div>
        </div>
        {hotel.map((item) => (
          <BookingContainer
            name={item.name}
            location={item.location}
            address={item.address}
            stars={item.stars}
            comment={item.comment}
            price={item.price}
            image1={item.image1}
            image2={item.image2}
            image3={item.image3}
            image4={item.image4}
            image5={item.image5}
            id={item.id}
            parking={item.parking}
            spa={item.spa}
            minbar={item.minbar}
            bathtub={item.bathtub}
            wifi={item.wifi}
            ocean={item.ocean}
            beach={item.beach}
            secure={item.secure}
            aircon={item.aircon}
            transportion={item.transportion}
            about={item.about}
          />
        ))}
      </>
    );
  }
};

export default MaildvesComponent;
