import React from "react";
import Hotel from "./Hotel";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const AboutHotel = ({ image, title, description }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [locations, setLocations] = useState([]);

  const getLocation = async () => {
    const response = await api.get("/location");
    return response.data;
  };

  useEffect(() => {
    const getAllLocationDetails = async () => {
      const allLocationData = await getLocation();
      if (allLocationData) {
        setIsLoaded(true);
        setLocations(allLocationData);
      }
    };

    getAllLocationDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (locations.length > 0) {
    return (
      <>
        {locations.map((item) => (
          <Hotel
            title={item.title}
            description={item.description}
            image={item.image}
            id={item.id}
          />
        ))}
      </>
    );
  } else {
    return (
      <div className="container ">
        <h5 className="text-center mb-5 mt-3 alert alert-danger">
          Locations Empty
        </h5>
      </div>
    );
  }
};

export default AboutHotel;
