import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderImage from "../Images/special.jpg";
import {
  faEllipsisVertical,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import SpecialCard from "../components/Specials/SpecialCard";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const Specials = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [specials, setSpecials] = useState([]);

  const getSpecial = async () => {
    const response = await api.get("/special");
    return response.data;
  };

  useEffect(() => {
    const getAllSpecialDetails = async () => {
      const allSpecialData = await getSpecial();
      if (allSpecialData) {
        setIsLoaded(true);
        setSpecials(allSpecialData);
      }
    };

    getAllSpecialDetails();
  }, []);
  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (specials.length > 0) {
    return (
      <div>
        <header>
          <div className="imageHeaderContainer">
            <img className="headerImage" src={HeaderImage} alt="test" />
          </div>
          <div className="headerText container text-center">
            <h2 className="">
              OUR <b>SPECIALS</b>
            </h2>

            <FontAwesomeIcon size="2x" icon={faEllipsisVertical} />
            <h3>See Our Running Specials</h3>
            <FontAwesomeIcon
              className="mt-3"
              size="2x"
              icon={faArrowAltCircleDown}
            />
            <div className="mt-5 ">
              <>
                <div className="s-details mb-2">
                  <strong> Special Details</strong>
                </div>
                {specials.map(item => (
                  <SpecialCard
                    start_date={item.start_date}
                    end_date={item.end_date}
                    special_name={item.special_name}
                    id={item.id}
                  />
                ))}
              </>
            </div>
          </div>
        </header>
        <div className="s-info-title mt-5 text-center">
          <h4>
            OUR <strong>SPECIALS</strong> DESIGNED FOR YOU
          </h4>

          <p className="container mt-4">
            Marie Lou Simaan the director of the company has been in the travel
            business for 37 years and has gained sustainable knowledge of the
            industry. All the staff presently employed are of an immediate and
            senior status and well travelled. We felt it was necessary to
            establish ourselves as a professional, valued and competitive agency
            in the world of travel which we felt was lacking.
          </p>
        </div>
      </div>
    );
  }
};

export default Specials;
