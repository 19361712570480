import Pages from "./pages/Pages";
import MessengerCustomerChat from "react-messenger-customer-chat";

const App = () => {
  return (
    <div className="App">
      <Pages />
      <MessengerCustomerChat
        pageId="353823991914721"
        appId="2064049870358985"
      />
      ,
    </div>
  );
};

export default App;
