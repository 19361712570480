import React, { useRef } from "react";
import "./ContactCard.css";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";

const ContactCard = ({ name, email, address, phoneNumber }) => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [meesage, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const form = useRef();

  const createHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("emailAddress", emailAddress);
    formData.append("phone", phone);
    formData.append("surname", surname);
    formData.append("message", meesage);

    await fetch(`/api/contact`, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        //   console.log(firstName,emailAddress, phone, surname, message)
        sendEmail();
        setShow(true);
      })
      .catch((e) => {});
  };

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_aaqeqrs",
        "template_snezyw9",
        form.current,
        "Bth1v4UDWPT4TLf7n"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Alert className="mb-5" show={show} variant="success">
        <Alert.Heading>Hi there!</Alert.Heading>
        <p>Thank you, your message has been submitted successfully</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close me
          </Button>
        </div>
      </Alert>
      <div className="container">
        <div className="wrapper">
          <div className="company-info">
            <h3>{name}</h3>

            <ul>
              <li>
                <i className="fa fa-road"></i> {address}
              </li>
              <li>
                <i className="fa fa-phone"></i> {phoneNumber}
              </li>
              <li>
                <i className="fa fa-envelope"></i> {email}
              </li>
            </ul>
          </div>

          <div className="contact">
            <h3>E-mail Us</h3>

            <form ref={form} id="contact-form">
              <p>
                <label>Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="name"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </p>

              <p>
                <label>Surname</label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  onChange={(e) => setSurname(e.target.value)}
                />
              </p>

              <p>
                <label>E-mail Address</label>
                <input
                  type="email"
                  name="emailAddress"
                  id="email"
                  required
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </p>

              <p>
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </p>

              <p className="full">
                <label>Message</label>
                <textarea
                  name="message"
                  rows="5"
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </p>

              <p className="full">
                <button
                  disabled={
                    !firstName ||
                    !emailAddress ||
                    !phone ||
                    !surname ||
                    !meesage
                  }
                  onClick={createHandler}
                  type="submit"
                >
                  SEND MESSSAGE NOW
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
