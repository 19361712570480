import React from "react";
import { Col, Row } from "react-bootstrap";
import HotelCard from "./HotelCard";

const Hotel = ({ image, title, description, id }) => {
  return (
    <div className="hotel">
      <Row>
        <Col className="col-12 col-sm-6 col-md-3 col-lg-6 image-conatiner">
          <>
            <img
              className="HotelImage"
              src={"/api/images/" + image}
              alt={image}
            />
          </>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 col-lg-6 content-conatiner">
          <HotelCard title={title} description={description} id={id} />
        </Col>
      </Row>
    </div>
  );
};

export default Hotel;
