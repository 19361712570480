import React from "react";
import { Routes, Route } from "react-router-dom";
import ContactUs from "./ContactUs";
import Home from "./Home";
import NavbarComponent from "../components/NavbarComponent";
import Destinations from "./Destinations";
import Footer from "../components/Footer";

import BookingComponent from "../components/Bookings/BookingComponent";
import AfricaComponent from "../components/Bookings/AfricaComponent";
import MadagascarComponent from "../components/Bookings/MadagascarComponent";
import SecyhellesComponent from "../components/Bookings/SecyhellesComponent";
import MaildvesComponent from "../components/Bookings/MaildvesComponent";
import MauritiusComponent from "../components/Bookings/MauritiusComponent";
import Specials from "./Specials";
import Special from "../components/Specials/Special";

const Pages = () => {
  return (
    <div>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/destination" element={<Destinations />} />
        <Route path="/specials" element={<Specials />} />
        <Route path="/" element={<Home />} />

        <Route path="/africa" element={<AfricaComponent />} />
        <Route path="/madagascar" element={<MadagascarComponent />} />
        <Route path="/maildves" element={<MaildvesComponent />} />
        <Route path="/secyhelles" element={<SecyhellesComponent />} />
        <Route path="/mauritius" element={<MauritiusComponent />} />

        <Route path="/booking/:id" element={<BookingComponent />} />
        <Route path="/booking/:id" element={<BookingComponent />} />
        <Route path="/booking/:id" element={<BookingComponent />} />
        <Route path="/booking/:id" element={<BookingComponent />} />
        <Route path="/booking/:id" element={<BookingComponent />} />
        <Route path="specials/special/:id" element={<Special />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Pages;
