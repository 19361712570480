import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import Maildives from "./Maildives";
import Madagascar from "./Madagascar";
import Africa from "./Africa";
import Secyhelles from "./Secyhelles";
import Mauritius from "./Mauritius";

const NavHotel = () => {
  return (
    <div>
      <Tabs
        fill
        defaultActiveKey="Madagascar"
        id="uncontrolled-tab-example"
        className="mb-3 tab"
      >
        <Tab eventKey="Madagascar" title="Madagascar">
          <Madagascar />
        </Tab>
        <Tab eventKey="Maildives" title="Maildives">
          <Maildives />
        </Tab>
        <Tab eventKey="Africa" title="Africa">
          <Africa />
        </Tab>
        <Tab eventKey="Secyhelles" title="Secyhelles">
          <Secyhelles />
        </Tab>
        <Tab eventKey="Mauritius" title="Mauritius">
          <Mauritius />
        </Tab>
      </Tabs>
    </div>
  );
};

export default NavHotel;
