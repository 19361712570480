import React from "react";
import ContactCard from "../components/ContactCard";
import Maps from "../components/Maps";
import ContactImage from "../Images/contact.jpg";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const ContactUs = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [companies, setCompanies] = useState([]);

  const getCompany = async () => {
    const response = await api.get("/company");
    return response.data;
  };

  useEffect(() => {
    const getAllCompanyDetails = async () => {
      const allCompanyData = await getCompany();
      if (allCompanyData) {
        setIsLoaded(true);
        setCompanies(allCompanyData);
      }
    };

    getAllCompanyDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (companies.length > 0) {
    return (
      <div className="conatct-main">
        <header>
          <div className="imageHeaderContainer">
            <img
              className="headerImage-contact"
              src={ContactImage}
              alt="test"
            />
          </div>
          <div className="headerText-contact container text-center">
            <h2 className="">GET IN TOUCH WITH US</h2>
          </div>
        </header>

        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              YOUR <b>JOURNEY </b>IS OUR PRIORITY
            </h5>

            <p className="booking-p">
              Marie Lou Simaan the director of the company has been in the
              travel business for 37 years and has gained sustainable knowledge
              of the industry. All the staff presently employed are of an
              immediate and senior status and well travelled. We felt it was
              necessary to establish ourselves as a professional, valued and
              competitive agency in the world of travel which we felt was
              lacking.
            </p>
          </div>
        </div>
        {companies.map((item) => (
          <ContactCard
            name={item.name}
            address={item.address}
            email={item.email}
            phoneNumber={item.phoneNumber}
          />
        ))}
      </div>
    );
  }
};

export default ContactUs;
