import React from "react";
import "./LocationCard.css";
import { Link } from "react-router-dom";

const LocationCard = ({
  name,
  address,
  stars,
  comment,
  price,
  location,
  image,
  id,
}) => {
  return (
    <div className="mt-5">
      <div className="card-container">
        <div className="hotel-image">
          <img
            src={"/api/images/" + image}
            alt={"/api/images/" + image}
          />
        </div>
        <div className="hotel-info">
          <div className="heading">
            <h1 className="title">{name}</h1>
            <h4 className="subtitle mt-4">{location}</h4>
            <h4 className="address">{address}</h4>
            {id}
          </div>
          <div className="reviews mt-5">
            <div className="hotel-class">
              <b>Hotel Class</b>
            </div>
            {stars === 5 ? (
              <ul className="stars">
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true">
                      <span />
                    </i>
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
              </ul>
            ) : (
              <></>
            )}
            {stars === 4 ? (
              <ul className="stars">
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true">
                      <span />
                    </i>
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
              </ul>
            ) : (
              <></>
            )}

            {stars === 3 ? (
              <ul className="stars">
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true">
                      <span />
                    </i>
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
              </ul>
            ) : (
              <></>
            )}

            {stars === 2 ? (
              <ul className="stars">
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true">
                      <span />
                    </i>
                  </span>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
              </ul>
            ) : (
              <></>
            )}

            {stars === 1 ? (
              <ul className="stars">
                <li>
                  <span className="yellow">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true">
                    <span />
                  </i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
              </ul>
            ) : (
              <></>
            )}

            <ul className="trip-advisor">
              <div className="comment">
                <b>Hotel Style</b>
              </div>
              <li>{comment}</li>
            </ul>
          </div>
        </div>
        <div className="hotel-price">
          <div className="pricing-content">
            <div className="guarantee">
              <div className="ml-3">
                <i className="fa fa-check-circle" aria-hidden="true" />
              </div>
              Price Guarantee
            </div>
            <div className="price">
              <h3>
                <span>Only from</span>
                <br />R {price}
              </h3>
            </div>
          </div>
          <Link to={`/booking/${id}`}>
            <button className="btn-cta">
              VIEW PACKAGE{" "}
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
