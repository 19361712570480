import React from "react";
import AboutContent from "../components/AboutContent";
import AboutHotel from "../components/AboutHotel";
import Header from "../components/Header";
import NavHotel from "../components/NavHotel";

const Home = () => {
  return (
    <div>
      <Header />
      <AboutContent />
      <AboutHotel />
      <NavHotel />
    </div>
  );
};

export default Home;
