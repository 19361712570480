import React from "react";
import LocationCard from "./LocationCard";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const Madagascar = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);

  const getHotel = async () => {
    const response = await api.get(`locationById/${17}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (hotel.length > 0) {
    return (
      <>
        {hotel.map((item) => (
          <LocationCard
            name={item.name}
            location={item.location}
            address={item.address}
            stars={item.stars}
            comment={item.comment}
            price={item.price}
            image={item.image1}
            id={item.id}
          />
        ))}
      </>
    );
  }
};

export default Madagascar;
