import React from "react";
import BookingContainer from "./BookingContainer";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../../api/mars_api";

const AfricaComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);

  const getHotel = async () => {
    const response = await api.get(`locationById/${19}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (hotel.length > 0) {
    return (
      <>
        <div className="booking-container ">
          <div className="booking-content">
            <h5 className="booking-h5">
              ABOUT THE <b>AFRICA</b>
            </h5>

            <p className="booking-p">
              Africa is the world's second-largest and second-most populous
              continent, after Asia in both cases. At about 30.3 million km2
              (11.7 million square miles) including adjacent islands, it covers
              6% of Earth's total surface area and 20% of its land area.[7] With
              1.3 billion people[1][2] as of 2018, it accounts for about 16% of
              the world's human population.
            </p>
          </div>
        </div>
        {hotel.map((item) => (
          <BookingContainer
            name={item.name}
            location={item.location}
            address={item.address}
            stars={item.stars}
            comment={item.comment}
            price={item.price}
            image1={item.image1}
            image2={item.image2}
            image3={item.image3}
            image4={item.image4}
            image5={item.image5}
            id={item.id}
            parking={item.parking}
            spa={item.spa}
            minbar={item.minbar}
            bathtub={item.bathtub}
            wifi={item.wifi}
            ocean={item.ocean}
            beach={item.beach}
            secure={item.secure}
            aircon={item.aircon}
            transportion={item.transportion}
            about={item.about}
          />
        ))}
      </>
    );
  }
};

export default AfricaComponent;
