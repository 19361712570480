import React from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";
import emailjs from "@emailjs/browser";

const HeaderEnquireForm = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [location, setLocation] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [destionation, setDestionation] = useState("");
  const [rooms, setRooms] = useState(0);
  const [adultNumber, setAdultNumber] = useState("");
  const [childrenNumber, setChildrenNumber] = useState("");
  const [checkIn, setCheckIn] = useState(0);
  const [checkOut, setCheckOut] = useState(0);
  const [show, setShow] = useState(false);
  const form = useRef();

  const createHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("destionation", destionation);
    formData.append("phoneNumber", phoneNumber);
    formData.append("rooms", rooms);
    formData.append("adultNumber", adultNumber);
    formData.append("childrenNumber", childrenNumber);
    formData.append("checkIn", checkIn);
    formData.append("checkOut", checkOut);

    await fetch(`/api/enquire`, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        sendEmail();
        setShow(true);
      })
      .catch((e) => {});
  };

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_aaqeqrs",
        "template_ib25fzh",
        form.current,
        "Bth1v4UDWPT4TLf7n"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const getsetLocation = async () => {
    const response = await api.get("location");
    return response.data;
  };

  useEffect(() => {
    const getAllLocationDetails = async () => {
      const allLocationData = await getsetLocation();
      if (allLocationData) {
        setIsLoaded(true);
        setLocation(allLocationData);
      }
    };

    getAllLocationDetails();
  }, []);

  return (
    <>
      <Alert className="mt-5" show={show} variant="success">
        <Alert.Heading>Hi there!</Alert.Heading>
        <p>Thank you, your enquiry has been submitted successfully</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close me
          </Button>
        </div>
      </Alert>
      <div className=" headerForm">
        <form className="form" ref={form}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Full Name(s)*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Type your first and last name... "
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Type your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Cell Number*</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  placeholder="Your mobile number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Label>Select Your Destination</Form.Label>
                <Form.Select
                  type="checkbox"
                  name="destionation"
                  label="Check me out"
                  onChange={(e) => setDestionation(e.target.value)}
                >
                  <option selected disabled>
                    Choose Destination
                  </option>
                  {location.map((item) => (
                    <option value={item.title}>{item.title}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Label>How many rooms?</Form.Label>
                <Form.Select
                  type="checkbox"
                  name="rooms"
                  label="Check me out"
                  onChange={(e) => setRooms(e.target.value)}
                >
                  <option selected disabled>
                    Number Of Rooms
                  </option>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Label>Adult</Form.Label>
                <Form.Select
                  type="checkbox"
                  name="adultNumber"
                  label="Check me out"
                  onChange={(e) => setAdultNumber(e.target.value)}
                >
                  <option selected disabled>
                    Number Of Adults
                  </option>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={4}>3</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Label>Children</Form.Label>
                <Form.Select
                  type="checkbox"
                  label="Check me out"
                  name="childrenNumber"
                  onChange={(e) => setChildrenNumber(e.target.value)}
                >
                  <option selected disabled>
                    Number Of Children
                  </option>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={4}>3</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Check In Date</Form.Label>
                <Form.Control
                  type="date"
                  name="checkIn"
                  placeholder="Check In "
                  onChange={(e) => setCheckIn(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Check Out Date</Form.Label>
                <Form.Control
                  type="date"
                  name="checkOut"
                  placeholder="Check Out "
                  onChange={(e) => setCheckOut(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-grid gap-2">
            <Button
              disabled={
                !name ||
                !email ||
                !phoneNumber ||
                !destionation ||
                !childrenNumber ||
                !adultNumber ||
                !rooms ||
                !checkIn ||
                !checkOut
              }
              onClick={createHandler}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default HeaderEnquireForm;
