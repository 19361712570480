import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../../api/mars_api";
import BookingContainer from "./BookingContainer";

const BookingComponent = () => {
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hotel, setHotel] = useState([]);

  const getHotel = async () => {
    const response = await api.get(`booking/${id}`);
    return response.data;
  };

  useEffect(() => {
    const getAllHotelDetails = async () => {
      const allHotelData = await getHotel();
      if (allHotelData) {
        setIsLoaded(true);
        setHotel(allHotelData);
      }
    };

    getAllHotelDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else {
    return (
      <div>
        <BookingContainer
          location_id={hotel.location_id}
          name={hotel.name}
          location={hotel.location}
          address={hotel.address}
          about={hotel.about}
          price={hotel.price}
          image1={hotel.image1}
          image2={hotel.image2}
          image3={hotel.image3}
          image4={hotel.image4}
          image5={hotel.image5}
          comment={hotel.comment}
          parking={hotel.parking}
          spa={hotel.spa}
          minbar={hotel.minbar}
          bathtub={hotel.bathtub}
          wifi={hotel.wifi}
          ocean={hotel.ocean}
          beach={hotel.beach}
          secure={hotel.secure}
          aircon={hotel.aircon}
          transportion={hotel.transportion}
        />
      </div>
    );
  }
};

export default BookingComponent;
