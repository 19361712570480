import React from "react";

const AboutContent = () => {
  return (
    <div className="aboutContent">
      <br />
      <div className="content container ">
        <h4>
          YOUR <b>JOURNEY</b> IS OUR PRIORITY
        </h4>
        <br />
        <p>
          Marie Lou Simaan the director of the company has been in the travel
          business for 37 years and has gained sustainable knowledge of the
          industry. All the staff presently employed are of an immediate and
          senior status and well travelled.
        </p>
        <br />
        <p>
          We felt it was necessary to establish ourselves as a professional,
          valued and competitive agency in the world of travel which we felt was
          lacking.
        </p>
        <br />
        <p>
          We have establishes ourselves as tour operators of various hotels in
          the world and hence can offer our clients directly, by cutting out the
          middleman, the best possible price and value. Our business is helping
          you do your business – 24 hours a day, seven days a week. We work
          extensivelyto please and get you to your destination as effortless as
          possible. Our strategy is to build on our position as market leader by
          focusing on our two core principals –Service and Value. We will
          continue to invest in the development of our people.
        </p>
      </div>
    </div>
  );
};

export default AboutContent;
