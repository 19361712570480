import React from "react";
import { Col, Row } from "react-bootstrap";
import Logo from "../Images/mars-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faPhoneAlt,
  faEnvelope,
  faAddressCard,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import api from "../api/mars_api";

const Footer = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [hotel, setHotel] = useState([]);

  const getCompany = async () => {
    const response = await api.get("/company");
    return response.data;
  };

  const getHotel = async () => {
    const response = await api.get("/booking");
    return response.data;
  };

  useEffect(() => {
    const getAllCompanyDetails = async () => {
      const allCompanyData = await getCompany();
      const allHotelData = await getHotel();
      if (allCompanyData) {
        setIsLoaded(true);
        setHotel(allHotelData);
        setCompanies(allCompanyData);
      }
    };

    getAllCompanyDetails();
  }, []);

  if (!isLoaded) {
    return (
      <div className="text-center m-5">
        <Spinner animation="border" /> Loading
      </div>
    );
  } else if (companies.length > 0) {
    return (
      <div className="footer">
        {companies.map(item => (
          <>
            <div className="footerLayOne m-5 ">
              <Row className="text-center">
                <Col>
                  <p>CONTACT US</p>
                  <ul class="list-unstyled">
                    <li>
                      <FontAwesomeIcon size="1x" icon={faPhoneAlt} />{" "}
                      {item.phoneNumber}
                    </li>
                    <li>
                      <FontAwesomeIcon size="1x" icon={faPhoneAlt} />{" "}
                      {item.phoneNumber2}
                    </li>
                    <li>
                      <FontAwesomeIcon size="1x" icon={faEnvelope} />{" "}
                      {item.email}
                    </li>
                    <li>
                      <FontAwesomeIcon size="1x" icon={faAddressCard} />
                      {item.address}
                    </li>
                  </ul>
                </Col>
                <Col>
                  <p>HOTELS & RESORTS</p>
                  <ul className="list-unstyled">
                    {hotel.map(item => (
                      <>
                        <li className="link">
                          <Link to={`booking/${item.id}`}>{item.name}</Link>
                        </li>
                      </>
                    ))}
                  </ul>
                </Col>
                <Col>
                  <p>{item.name}</p>
                  <img
                    className="footerLogo"
                    src={"/api/images/" + item.image}
                    alt={Logo}
                  />
                </Col>
              </Row>
            </div>
            <div className="footerLayTwo ">
              <div className="socialCard">
                <Row className="text-center ">
                  <Col>© {item.name}. All Rights Reserved.</Col>
                  <Col>
                    <ul className="social-icons">
                      <li className="link">
                        <a target="_blank" href={item.facebook}>
                          <i class="fa-brands fa-facebook"></i>
                        </a>
                      </li>
                      <li className="link">
                        <a target="_blank" href={item.twitter}>
                          {" "}
                          <i class="fa-brands fa-twitter-square"></i>
                        </a>
                      </li>
                      <li className="link">
                        <a target="_blank" href={item.google}>
                          <i class="fa-brands fa-google"></i>
                        </a>
                      </li>
                      <li className="link">
                        <a target="_blank" href={item.instagram}>
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col>Privacy Policy</Col>
                </Row>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
};

export default Footer;
